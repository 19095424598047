<template>
  <footer v-editable="config" :data-theme="pageTheme">
    <div class="container container--bleed">
      <div class="row row--full">
        <div class="footer-logo">
          <a v-if="logo?.filename" href="/" title="Kudosity Logo">
            <NuxtImg provider="storyblok" width="218" height="48" loading="lazy" :src="logo?.filename" alt="Kudosity Logo" />
          </a>
        </div>
      </div>
      <div class="row row--full footer-menu-row">
        <div v-for="(group, g) in config.footer_menu" :key="g" v-editable="group" class="footer-menu-group">
          <div v-for="(menu, m) in group.items" :key="m" v-editable="menu" class="footer-menu">
            <template v-if="menu?.label">
              <a v-if="menu?.link?.cached_url" :href="getLinkFieldUrl(menu?.link)" class="footer-menu-label" :target="menu?.link?.target" v-text="menu.label" />
              <div v-else class="footer-menu-label" v-text="menu.label" />
            </template>
            <ul class="footer-menu-list">
              <li v-for="(link, l) in menu.menu" :key="l" v-editable="link" class="footer-link">
                <a :href="getLinkFieldUrl(link.link)" v-text="link.label" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row row-full">
        <div class="footer-sub">
          <div v-if="config.social_links" class="footer-social-links">
            <a v-for="(link, l) in config.social_links" :key="l" v-editable="link" :href="getLinkFieldUrl(link.url)" class="footer-social-link" title="`Kudosity ${link.icon} URL`">
              <img loading="lazy" :src="`/icons/icon-${link.icon}-${theme}.svg`" :alt="`Social Icon ${link.icon}`" height="24" width="24" />
            </a>
          </div>
          <div class="footer-copyright" v-text="config.copyright" />
          <div v-if="config.footer_submenu" class="footer-submenu">
            <a v-for="(link, l) in config.footer_submenu" :key="l" v-editable="link" :href="getLinkFieldUrl(link.link)" class="footer-submenu-link" :data-countries="link.showOnlyOnCountries || null" v-text="link.label" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
  import { usePageStore } from "@/stores/page";
  import type { ConfigStoryblok } from "@/types/storyblok";
  const { getCountryInfo } = useGeoLocation();
  const props = defineProps<{
    config: ConfigStoryblok;
  }>();

  const pageStore = usePageStore();
  const theme = computed(() => pageStore.footer_theme);

  const pageTheme = ref("dark");

  watch(
    pageStore,
    async (state) => {
      await nextTick();
      pageTheme.value = state.footer_theme;
      if (document) document?.querySelector("footer")?.setAttribute("data-theme", state.footer_theme);
    },
    { deep: true }
  );

  const logo = computed(() => {
    if (theme.value === "light") {
      return props.config.footer_logo_dark;
    }
    return props.config.footer_logo;
  });

  const showLinksByCountry = (country: string) => {
    document.querySelectorAll("footer .footer-submenu-link[data-countries]").forEach((el) => {
      const countries = el.getAttribute("data-countries");

      if (countries) {
        const countryList = countries.split(",");
        if (countryList.includes(country)) {
          (el as HTMLElement).style.display = "block";
        }
      }
    });
  };

  onMounted(() => {
    if (import.meta.client) {
      requestAnimationFrame(async () => {
        const countryInfo = await getCountryInfo();

        if (countryInfo?.country?.code) {
          document?.querySelector("html")?.setAttribute("data-country", countryInfo?.country?.code);
          showLinksByCountry(countryInfo?.country?.code);
        }
      });
    }
  });
</script>
<style src="~/assets/scss/layout/footer.scss" lang="scss"></style>
