export const useGeoLocation = () => {
  const fetchCountryInfo = async (): Promise<{ country: { name: string; code: string } }> => {
    try {
      const response = await fetch("/.netlify/functions/info");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      if (typeof sessionStorage !== "undefined") sessionStorage.setItem("kudosity-geo", JSON.stringify(data));
      return data;
    } catch (error) {
      console.warn("Could not fetch country code", error);
      const defaultData = { country: { name: "Unknown", code: "-" } };
      if (typeof sessionStorage !== "undefined") sessionStorage.setItem("kudosity-geo", JSON.stringify(defaultData));
      return defaultData;
    }
  };

  const getCountryInfo = async (): Promise<{ country: { name: string; code: string } }> => {
    if (typeof sessionStorage !== "undefined") {
      const storedData = sessionStorage.getItem("kudosity-geo");
      if (storedData) {
        return JSON.parse(storedData);
      }
    }
    return await fetchCountryInfo();
  };

  return {
    getCountryInfo,
  };
};
